var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    ref: "map-root",
    staticClass: "mb-2",
    staticStyle: {
      height: "300px",
      border: "1px solid #ccc",
      background: "white",
    },
    attrs: { id: "map" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }