<template>
  <div>
    <v-row class="ml-1 mr-2 my-2">
      <v-col cols="4" sm="4" lg="1" xl="1" md="1" class="py-0 px-1">
        <v-select
          v-model="selectedState"
          :items="states"
          item-value="state"
          item-text="state"
          :menu-props="{ bottom: true, offsetY: true }"
          outlined
          required
          :hide-details="$vuetify.breakpoint.mdAndUp ? true : false"
          dense
          :label="$t('state')"
          @change="onSelectState"
          @blur="onSelectState"
        >
          <template v-slot:item="{ item, on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-content>
                <v-list-item-title class="text-left">{{
                  item.state
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" lg="5" xl="5" md="5" sm="12" class="py-0 mb-2 px-1">
        <v-row>
          <v-col cols="11">
            <!-- <v-text-field
              v-model="search"
              variant="outlined"
              label="Address"
            ></v-text-field> -->
            <v-combobox
              fluid
              :v-model="select"
              :items="autoSuggestAddress"
              :search-input.sync="search"
              flat
              filled
              outlined
              dense
              solo
              clearable
              :disabled="!selectedState"
              append-icon=""
              hide-details
              placeholder="Search Location."
              label=""
              hide-no-data
              auto-select-first
              prepend-inner-icon="mdi-magnify"
              ref="searchBox"
            ></v-combobox>
          </v-col>
          <v-col cols="1" class="px-0">
            <v-icon
              class="mt-2 lookup-info-icon"
              color="primary"
              @click="lookupDialog = true"
              aria-label="Map Lookup types"
            >
              mdi-information-outline
            </v-icon>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        lg="2"
        sm="12"
        xl="2"
        md="2"
        :class="$vuetify.breakpoint.mdAndUp ? 'px-1 py-0' : 'my-2 px-1'"
      >
        <v-btn
          color="primary"
          class="mx-auto font-weight-regular text-none rounded-lg"
          block
          :disabled="!search || searching"
          @click="doSearch()"
          ><v-icon class="mr-2">mdi-magnify</v-icon> {{ $t("searchAddress")
          }}<v-progress-circular
            v-if="searching"
            indeterminate
            size="20"
            color="white"
            class="ml-2"
          ></v-progress-circular
        ></v-btn>
      </v-col>
    </v-row>
    <v-divider
      v-if="
        !isAddressFound &&
        Object.keys(addressGeoJson).length !== 0 &&
        $vuetify.breakpoint.smAndDown
      "
      class="mt-2"
    ></v-divider>
    <v-row
      v-if="showLatLongDrawMsg"
      class="px-4 py-2 mx-auto text-center mb-3 mt-0"
      justify="center"
    >
      <div class="d-flex align-center">
        <span
          >The latitude and longitude entered is shown on the map. Please draw
          excavation area.</span
        >
      </div>
    </v-row>
    <v-row
      v-if="isAddressFound && $vuetify.breakpoint.mdAndUp"
      class="px-4 py-2 mx-auto text-center mb-3 mt-0"
      justify="center"
    >
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
      <div class="d-flex align-center">
        <span>{{ descriptonMessage }}</span>
        <div>
          <v-select
            v-if="showSelectPlace"
            v-model="selectedPlace"
            :items="
              places.map((item) => {
                return item.place;
              })
            "
            label="Places"
            class="mx-4"
            required
          ></v-select>
        </div>
        <v-btn
          color="primary"
          class="text-none rounded-lg font-weight-regular white--text mx-3"
          height="30"
          :disabled="disableContinueButton"
          @click="onConfirmLocation"
          >{{ $t("yes") }}
        </v-btn>
        <v-btn
          color="primary"
          class="text-none rounded-lg font-weight-regular white--text"
          height="30"
          @click="clickNo"
          >{{ $t("no") }}
        </v-btn>
      </div>
      <div
        v-if="isLatLongFormat"
        class="d-flex align-center"
        style="width: 100%"
      >
        <v-text-field
          v-model="street"
          variant="outlined"
          label="ex: 100 Main St"
          width="200"
        ></v-text-field>
      </div>
      <v-spacer></v-spacer>
    </v-row>
    <v-row
      v-if="isAddressFound && $vuetify.breakpoint.smAndDown"
      class="mx-auto text-center mb-3 mt-0"
      justify="center"
    >
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
      <v-col class="px-0" cols="8" md="12" sm="12">
        <span>{{ descriptonMessage }}</span>
        <v-select
          v-if="showSelectPlace"
          v-model="selectedPlace"
          :items="
            places.map((item) => {
              return item.place;
            })
          "
          label="Places"
          required
        ></v-select></v-col
      ><v-col cols="6" sm="6">
        <v-btn
          color="primary"
          class="text-none rounded-lg font-weight-regular white--text"
          block
          height="30"
          :disabled="disableContinueButton"
          @click="onConfirmLocation"
          >{{ $t("yes") }}
        </v-btn> </v-col
      ><v-col cols="6" sm="6">
        <v-btn
          color="primary"
          class="text-none rounded-lg font-weight-regular white--text"
          block
          height="30"
          @click="clickNo"
          >{{ $t("no") }}
        </v-btn> </v-col
      ><v-spacer></v-spacer>
    </v-row>

    <v-row
      v-if="noAddressErrorMessage"
      class="px-4 py-2 mx-auto text-center mb-3 mt-0"
      justify="center"
    >
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
      <v-col
        class="px-0 red--text py-0"
        cols="12"
        xl="12"
        lg="12"
        md="12"
        sm="9"
      >
        {{ noAddressErrorMessage }}
        <v-btn
          color="primary"
          class="text-none rounded-lg font-weight-regular white--text ml-2"
          height="35"
          @click="onClickOK"
          >{{ $t("ok") }}
        </v-btn></v-col
      ><v-spacer></v-spacer>
    </v-row>
    <v-dialog v-model="lookupDialog">
      <v-card>
        <v-card-title>
          <h1>Map Lookup Types</h1>
        </v-card-title>
        <v-card-text class="pt-2 ma-0">
          <div class="py-2">
            <h2>Single Address</h2>
            <p class="pt-2 ma-0">
              Enter a street address and city. The address can be a street and
              number or just a street.
            </p>
            <p class="py-2 ma-0">Examples:</p>
            <ol>
              <li>1024 Essington Rd, Joliet Cit</li>
              <li>Essington Rd, Joliet Cit</li>
            </ol>
          </div>
          <hr />
          <div class="py-2">
            <h2>Intersection</h2>
            <p class="pt-2 ma-0">
              Enter a street with city and a cross street. The address must be
              just a street with no address number.
            </p>
            <p class="pt-2 ma-0">Example:</p>
            <ol>
              <li>Essington Rd &amp; Excutive Dr, Joliet Cit</li>
            </ol>
          </div>
          <hr />
          <div class="py-2">
            <h2>Between two cross streets</h2>
            <p class="pt-2 ma-0">
              Enter a street with city and two cross streets. The address must
              be just a street with no address number.
            </p>
            <p class="pt-2 ma-0">Example:</p>
            <ol>
              <li>
                Essington Rd &amp; Executive Dr &amp; Ingalls Ave, Joliet Cit
              </li>
            </ol>
          </div>
          <hr />
          <div class="py-2">
            <h2>Latitude &amp; Longitude</h2>
            <p class="pt-2 ma-0">
              Enter a latitude and longitude coordinate in decimal degrees.
            </p>
            <p class="pt-2 ma-0">Example:</p>
            <ol>
              <li>39.796095, -89.651357</li>
            </ol>
          </div>
          <hr />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green-darken-1"
            variant="text"
            @click="lookupDialog = false"
          >
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import * as format from "ol/format";
import { bus } from "@/main";
import * as wellknown from "wellknown";
import LocationMixin from "@/mixins/LocationMixin";
import * as turf from "@turf/turf";
const townShipHandlingStates = ["NC"];
export default {
  props: {
    isEditView: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [LocationMixin],
  data() {
    return {
      lookupTypes: [
        "address",
        "street",
        "intersection",
        "between",
        "coordinates",
      ],
      states: [],
      counties: [],
      places: [],
      selectedState: "",
      selectedLookupType: "address",
      lookupDialog: false,
      gotHit: false,
      descriptonMessage: null,
      selectedCenter: "",
      selectedPlace: "",
      selectedCounty: "",
      noAddressErrorMessage: "",
      showAddressNotFoundError: false,
      address: "",
      search: null,
      select: null,
      autoSuggestAddress: [],
      addressGeoJson: {},
      isAddressFound: false,
      showDrawConfirmButton: false,
      isDrawingDone: false,
      isDrawingActive: false,
      stateToCenterMap: {},
      incorrectAddressDialog: false,
      streetSearchResults: [],
      searchLookupFound: false,
      lookupFoundMessage: "",
      street: null,
      geoJson: null,
      isLatLongFormat: false,
      searching: false,
      showSelectPlace: false,
      showLatLongDrawMsg: false,
      streetLocation: null,
    };
  },

  computed: {
    newtinAuthorizeCentersList() {
      let centersList = [];
      if (
        Object.keys(this.$store.state.Company.company).length > 0 &&
        this.$store.state.Company.company.centers &&
        this.$store.state.Company.company.centers.length > 0
      ) {
        centersList = this.$store.state.Company.company.centers.map((item) => {
          return {
            centerName: item.abbrev,
            state: item.state,
            center_id: item.center_id,
            excavator_type: item.excavator_type,
            default: item.default,
          };
        });
      }
      return centersList;
    },
    disableContinueButton() {
      if (this.isLatLongFormat && !this.street) {
        return true;
      }
      return false;
    },
  },
  watch: {
    search(val) {
      if (val !== this.select) {
        this.isAddressFound = false;
        if (this.search) this.fetchEntriesDebounced();
      }
    },
    async newtinAuthorizeCentersList(val) {
      if (this.newtinAuthorizeCentersList) {
        await this.getStates();
      }
    },
  },
  mounted() {
    this.setLocationData();
  },
  async created() {
    if (this.newtinAuthorizeCentersList) {
      await this.getStates();
    }
    this.bindBusEvents();
    let ticketData = this.$store.state.Ticket.ticketData;
    if (Object.keys(ticketData).length) {
      const searchString = ticketData.search
        ? ticketData.search
        : ticketData.street
        ? `${ticketData.street}, ${ticketData.place}`
        : null;
      this.select = searchString;
      this.search = searchString;
      this.parcelExactInPlace = ticketData.options?.parcelExactInPlace || 0;
      this.setInitialDataFromStore();
    }
  },
  methods: {
    fetchEntriesDebounced() {
      const isLatLongFormat = (str) => {
        const latLongRegex =
          /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*?[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
        return latLongRegex.test(str);
      };
      const isBetweenStreetFormat = (str) => {
        const betweenRegex = /(^btwn\s|\sbtwn\s|\sbtwn$)/i;
        return betweenRegex.test(str);
      };
      const isCrossStreetFormat = (str) => {
        const crossRegex = /^\s*[^&]*\s&\s[^&]*\s*$/;
        return crossRegex.test(str);
      };
      this.isCrossStreet = false;
      this.isBetweenStreet = false;
      this.isLatLongFormat = false;
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.autoSuggestAddress = [];
        if (isBetweenStreetFormat(this.search)) {
          this.isBetweenStreet = true;
        } else if (isCrossStreetFormat(this.search)) {
          this.isCrossStreet = true;
          this.fetchCrossStreetsForAutosuggest();
        } else if (isLatLongFormat(this.search)) {
          this.isLatLongFormat = true;
        } else {
          this.fetchStreets();
        }
      }, 500);
    },
    async fetchStreets() {
      //this.isAddressFound = false;
      this.addressGeoJson = [];
      const prefixArray = (array, prefix) =>
        array.map(
          (e) => `${prefix !== "0" ? prefix : ""} ${e.fullname}, ${e.location}`
        );
      const bodyObject = {};
      bodyObject.body = {
        center: this.stateToCenterMap[this.selectedState],
        autoSuggestAddress: this.search,
      };
      const autoSuggestAddressStatus = await this.$store.dispatch(
        "getAutoSuggestion",
        bodyObject
      );

      if (autoSuggestAddressStatus.status !== "error") {
        this.autoSuggestAddress = prefixArray(
          autoSuggestAddressStatus.suggestions,
          autoSuggestAddressStatus.normalized.address
        );
        const suggestions = autoSuggestAddressStatus.suggestions.filter(
          (item) => {
            return item.location !== "";
          }
        );
        this.streetSearchResults = suggestions.map((item) => {
          item.address = `${
            autoSuggestAddressStatus.normalized.address !== "0"
              ? autoSuggestAddressStatus.normalized.address
              : ""
          } ${item.fullname}, ${item.location}`;
          return item;
        });
      }
    },
    async fetchCrossStreetsForAutosuggest() {
      this.isAddressFound = false;
      this.addressGeoJson = [];
      const transformArray = (array) =>
        array.map((e) => `${e.street} & ${e.cross}, ${e.location}`);
      const bodyObject = {};
      bodyObject.body = {
        center: this.stateToCenterMap[this.selectedState],
        address: this.search,
      };
      const autoSuggestAddressStatus = await this.$store.dispatch(
        "getAutoSuggestionCross",
        bodyObject
      );

      if (autoSuggestAddressStatus.status !== "error") {
        const intersections = autoSuggestAddressStatus.intersections.filter(
          (item) => {
            return item.location !== "";
          }
        );
        this.autoSuggestAddress = transformArray(intersections);

        this.crossStreetSearchResults =
          autoSuggestAddressStatus.intersections.map((item) => {
            item.address =
              `${item.street} & ${item.cross}, ${item.location}`.toLowerCase();
            return item;
          });
      }
    },
    async setLocationData() {
      let ticketData = this.$store.state.Ticket.ticketData;
      if (Object.keys(ticketData).length) {
        if (ticketData.state) {
          this.selectedState = ticketData.state;
          if (this.$store.state.Ticket.isEdit === "createTicket") {
            this.$nextTick(() => {
              this.onSelectState();
            });
          }
          this.selectedCenterId = ticketData.center_id;
          this.callerType = ticketData.callerType;
        }
        if (ticketData.county) {
          this.selectedCounty = ticketData.county;
        }
        if (ticketData.place) {
          this.selectedPlace = ticketData.place;
        }
        if (ticketData.center) this.selectedCenter = ticketData.center;
        if (ticketData.addressType) this.addressType = ticketData.addressType;
        if (ticketData.parcelExactInPlace)
          this.parcelExactInPlace = ticketData.parcelExactInPlace;
        if (ticketData.addressType) this.isAddressFound = true;
        if (ticketData.addressType) this.getDescriptionMessage();
      }
    },
    bindBusEvents() {
      bus.$on("drawButtonClicked", this.resetSearchState);
      bus.$on("showVerify", async (obj) => {
        this.selectedCounty = obj.description.counties[0].name;
        this.selectedPlace = obj.description.places[0].name;
        const places = await this.getPlaces(
          this.selectedCenter,
          this.selectedState,
          this.selectedCounty
        );
        this.places = places;
        const isValidPlace = this.checkPlaceWithinCounty(
          places,
          this.selectedPlace
        );

        if (!isValidPlace) {
          this.isAddressFound = true;
          this.showSelectPlace = true;
          let currentPlace = this.selectedPlace;
          this.descriptonMessage = `${obj.msg} We found location ${currentPlace} which is not a valid place. Please select a place. `;
          this.selectedPlace = places[0].place;
        } else {
          this.isAddressFound = true;
          this.descriptonMessage = obj.msg;
        }
        const parts = this.search ? this.search.split(",") : [];
        if (parts.length === 2 && !isNaN(parts[0]) && !isNaN(parts[1])) {
          this.street = obj.description.street;
        }
      });
      bus.$on("showNostreetMsg", (msg) => {
        this.isAddressFound = false;
        this.noAddressErrorMessage = msg;
      });
      bus.$on("coordinatesNotFound", (msg) => {
        this.isAddressFound = false;
        this.noAddressErrorMessage = msg;
      });
      bus.$on("coordinatesAdded", () => {
        this.showLatLongDrawMsg = true;
      });
    },
    doSearch() {
      this.resetSearchState();
      this.street = null;
      this.streetFromAddress = null;
      this.searching = true;
      this.$store.state.Ticket.searchOptions = {
        search: this.search,
        state: this.selectedState,
        center: this.selectedCenter,
        place: this.selectedPlace,
        county: this.selectedCounty,
      };
      bus.$emit("clearMap");
      let search = this.search ? this.search : "";
      const parts = search.split(",");
      if (parts.length === 2 && !isNaN(parts[0]) && !isNaN(parts[1])) {
        this.showCoordinates();
      } else if ((search.match(/&/g) || []).length === 1) {
        this.fetchCrossStreets();
      } else if ((search.match(/&/g) || []).length === 2) {
        this.fetchBetweenStreets();
      } else {
        this.fetchAddressOrStreets();
      }
    },
    async fetchAddressOrStreets() {
      let bodyObject = {};
      // check if street input contains address number eg.  3275 Executive Dr, place name
      const regexWithNumber = /^(\d+)\s+([^,]+),\s*(.+)$/;
      // check if street input only has street name eg. Executive Dr, place name
      const regexWithoutNumber = /^([^,]+),\s*(.+)$/;
      //const addressRegex = /^\d+\s+[A-Za-z\s]+/;
      let match = this.search.match(regexWithNumber);
      if (match) {
        this.streetFromAddress = match[1];
        this.street = match[2];
      } else {
        match = this.search.match(regexWithoutNumber);
        if (match) {
          this.streetFromAddress = null;
          this.street = match[1];
        }
      }
      bodyObject.body = {
        center: this.stateToCenterMap[this.selectedState].toLowerCase(),
        autoSuggestAddress: this.search,
      };
      const addresStatus = await this.$store.dispatch("getAddress", bodyObject);

      let features = [];
      if (addresStatus.status !== "error") {
        const enteredLocation = this.search.split(",")[1];
        if (
          addresStatus.addresses.length ||
          addresStatus.parcels.length ||
          addresStatus.streets.length
        ) {
          this.parcelExactInPlace = 0;
          if (addresStatus.parcels?.length > 0) {
            const exactParcel = addresStatus.parcels.find(
              (item) =>
                addresStatus.normalized.fullAddress.toLowerCase() ===
                item?.addr_full?.toLowerCase()
            );
            features.push(exactParcel || addresStatus.parcels[0]);
            // In case we found multiple parcels select correct one
            if (addresStatus.parcels.length > 1) {
              let validParcels = [];
              let previousCounty = null;
              for (const parcel of addresStatus.parcels) {
                if (parcel.county !== previousCounty) {
                  previousCounty = parcel.county;
                  const places = await this.getPlaces(
                    this.selectedCenter,
                    this.selectedState,
                    parcel.county
                  );
                  this.places = places;
                }
                const placesNames = this.places.map((item) => item.place);
                if (placesNames.includes(parcel.place.toUpperCase())) {
                  validParcels.push(parcel);
                }
              }
              this.selectedCounty = validParcels[0].county;
              this.selectedPlace = validParcels[0].place;
            } else {
              this.selectedCounty = addresStatus.parcels[0].county;
              this.selectedPlace = addresStatus.parcels[0].place;
            }
            this.addressType = "parcel";
            if (this.townshipHandlingRequired(addresStatus.parcels[0])) {
              this.handleTownShip(addresStatus.parcels[0]);
            } else {
              if (
                this.selectedPlace.toUpperCase() !==
                enteredLocation.trim().toUpperCase()
              ) {
                this.descriptonMessage = `We have found a parcel with that address and have selected it as the area of excavation. However we can't find location "${enteredLocation.toUpperCase()}", instead we found the location "${this.selectedPlace.toUpperCase()}".If this is correct select "Yes". If it is not correct then select "No" and search again or draw the location of where you are excavating.`;
              } else {
                this.descriptonMessage = `We have found a parcel with that address and have selected it as the area of excavation.If this is correct select "Yes". If it is not correct then select "No" and search again or draw the location of where you are excavating.`;
              }
            }
          }
          if (addresStatus.addresses?.length > 0) {
            const exactAddress = addresStatus.addresses.find(
              (item) =>
                addresStatus.normalized.fullAddress.toLowerCase() ===
                item?.addr_full?.toLowerCase()
            );
            features.push(exactAddress || addresStatus.addresses[0]);
            if (!this.addressType) {
              if (addresStatus.addresses.length > 1) {
                let validAddress = [];
                let previousCounty = null;
                for (const address of addresStatus.addresses) {
                  if (address.county !== previousCounty) {
                    previousCounty = address.county;
                    const places = await this.getPlaces(
                      this.selectedCenter,
                      this.selectedState,
                      address.county
                    );
                    this.places = places;
                  }

                  const placesNames = this.places.map((item) => item.place);
                  if (placesNames.includes(address.place.toUpperCase())) {
                    validAddress.push(address);
                  }
                }
                this.selectedCounty = validAddress[0].county;
                this.selectedPlace = validAddress[0].place;
              } else {
                this.selectedCounty = addresStatus.addresses[0].county;
                this.selectedPlace = addresStatus.addresses[0].place;
              }
              this.addressType = "point";
              if (this.townshipHandlingRequired(addresStatus.addresses[0])) {
                this.handleTownShip(addresStatus.addresses[0]);
              } else {
                if (
                  this.selectedPlace.toUpperCase() !==
                  enteredLocation.trim().toUpperCase()
                ) {
                  this.descriptonMessage = `We have found the address point with that address and have selected it as the area of excavation. However we can't find location "${enteredLocation.toUpperCase()}", instead we found the location "${this.selectedPlace.toUpperCase()}". If this is correct select "Yes". If it is not correct then select "No" and search again or draw the location of where you are excavating`;
                } else {
                  this.descriptonMessage = `We have found the address point with that address and have selected it as the area of excavation.If this is correct select "Yes". If it is not correct then select "No" and search again or draw the location of where you are excavating`;
                }
              }
            }
          }
          if (addresStatus.streets?.length > 0) {
            features.push(addresStatus.streets[0]);
            if (!this.addressType) {
              if (addresStatus.streets.length > 1) {
                let validStreet = [];
                let previousCounty = null;
                for (const street of addresStatus.streets) {
                  if (street.county !== previousCounty) {
                    previousCounty = street.county;
                    const places = await this.getPlaces(
                      this.selectedCenter,
                      this.selectedState,
                      street.county
                    );
                    this.places = places;
                  }

                  const placesNames = this.places.map((item) => item.place);
                  if (placesNames.includes(street.place.toUpperCase())) {
                    validStreet.push(street);
                  }
                }
                this.selectedCounty = validStreet[0].county;
                this.selectedPlace = validStreet[0].place;
              } else {
                this.selectedCounty = addresStatus.streets[0].county;
                this.selectedPlace = addresStatus.streets[0].place;
              }
              this.selectedCounty = addresStatus.streets[0].county;
              this.selectedPlace = addresStatus.streets[0].place;
              this.addressType = "street";
              if (this.townshipHandlingRequired(addresStatus.streets[0])) {
                this.handleTownShip(addresStatus.streets[0]);
              } else {
                if (
                  this.selectedPlace.toUpperCase() !==
                  enteredLocation.trim().toUpperCase()
                ) {
                  this.descriptonMessage = `We have found the street for that address and have selected it as the area of excavation.However we can't find location "${enteredLocation.toUpperCase()}", instead we found the location "${this.selectedPlace.toUpperCase()}". If this is correct select "Yes". If it is not correct then select "No" and search again or draw the location of where you are excavating`;
                } else {
                  this.descriptonMessage = `We have found the street for that address and have selected it as the area of excavation. If this is correct select "Yes". If it is not correct then select "No" and search again or draw the location of where you are excavating`;
                }
              }
            }
          }
          this.geoAddress = this.convertObjectToFeature(features);
          this.geoJson = {
            type: "FeatureCollection",
            features: this.geoAddress,
          };
          bus.$emit("addFeature", {
            feature: this.geoJson,
            parcelIndex: this.parcelExactInPlace,
          });
          this.isAddressFound = true;
        } else {
          if (addresStatus.ranges.length) {
            for (const range of addresStatus.ranges) {
              //county = range.county;
              const feature = JSON.parse(
                new format.GeoJSON().writeFeatures(
                  new format.WKT().readFeatures(range.line)
                )
              );
              bus.$emit("addFeature", {
                feature: feature,
              });
            }
            this.isAddressFound = false;
            this.noAddressErrorMessage = `Address not found, showing the street in the specified place. You can select "OK" then search again or draw the location of where you are excavating.`;
          } else if (addresStatus.places.length) {
            for (const range of addresStatus.places) {
              const feature = JSON.parse(
                new format.GeoJSON().writeFeatures(
                  new format.WKT().readFeatures(range.geom)
                )
              );
              bus.$emit("addFeature", {
                feature: feature,
              });
            }
            this.selectedPlace = addresStatus.places[0].location;
            this.isAddressFound = false;
            this.noAddressErrorMessage = `Street not found, showing all of "${this.selectedPlace}". You can select "OK" then search again or draw the location of where you are excavating.`;
          } else {
            this.isAddressFound = false;
            this.noAddressErrorMessage =
              "Address not found. Search again, or draw the work area";
          }
        }
      } else {
        this.isAddressFound = false;
        this.noAddressErrorMessage = addresStatus.error;
      }
      this.searching = false;
    },
    async fetchCrossStreets() {
      let place = this.search.split(",")[1];
      if (!place) {
        this.isAddressFound = false;
        this.noAddressErrorMessage = "Address requires place name";
        this.searching = false;
        return;
      }
      let response = await fetch(
        `${
          process.env.VUE_APP_NEWTIN_GEOLOCATOR_API
        }/geocoder/intersections?center=${
          this.selectedCenter
        }&address=${encodeURIComponent(this.search)}`
      );
      let data = await response.json();
      let features = [];
      if (data.intersections.length > 0) {
        this.street = this.search.split("&")[0];
        this.selectedCounty = data.intersections[0].county;
        this.selectedPlace = data.intersections[0].location;
        features = [
          { point: data.intersections[0].point },
          { LineString: data.intersections[0].street_line },
          { LineString: data.intersections[0].cross_line },
        ];
        this.geoAddress = this.convertObjectToFeature(features);
        this.geoJson = {
          type: "FeatureCollection",
          features: this.geoAddress,
        };
        this.parcelExactInPlace = 0;
        bus.$emit("addFeature", {
          feature: this.geoJson,
          parcelIndex: 0,
        });
        this.addressType = "intersection";
        const places = await this.getPlaces(
          this.selectedCenter,
          this.selectedState,
          this.selectedCounty
        );
        this.places = places;
        const isValidPlace = this.checkPlaceWithinCounty(
          places,
          this.selectedPlace
        );

        if (!isValidPlace) {
          let feature = JSON.parse(
            new format.GeoJSON().writeFeatures(
              new format.WKT().readFeatures(data.intersections[0].point)
            )
          );
          let drawnFeature = this.getFeatureFromCoordinates(
            feature.features[0].geometry.type,
            feature.features[0].geometry.coordinates
          );
          drawnFeature = new format.GeoJSON().readFeatureFromObject(
            drawnFeature
          );
          const description = await this.getDescription(
            drawnFeature.getGeometry()
          );
          let validPlace;
          if (description.places.length > 1) {
            const placesNames = places.map((item) => item.place);
            for (const item of description.places) {
              const nameToCheck = item.name.toUpperCase();
              if (placesNames.includes(nameToCheck)) {
                validPlace = item.name;
                break; // If a match is found, then break the loop
              }
            }
          }
          if (validPlace) {
            if (validPlace !== this.selectedPlace) {
              this.isAddressFound = true;
              const currentLocation = this.selectedPlace;
              this.selectedPlace = validPlace;
              this.descriptonMessage = `We have found the intersection for that street and cross street and have selected it as the area of excavation. However we can't find location "${currentLocation.toUpperCase()}", instead we found the location "${validPlace.toUpperCase()}". If this is correct select "Yes". If it is not correct then select "No" and search again or draw the location of where you are excavating.`;
            }
          } else {
            this.isAddressFound = true;
            this.showSelectPlace = true;
            let currentPlace = this.selectedPlace;
            this.descriptonMessage = `${currentPlace} is not a valid place. Please select a place. `;
            this.selectedPlace = places[0].place;
          }
        } else {
          this.isAddressFound = true;
          this.descriptonMessage = `We have found the intersection for that street and cross street and have selected it as the area of excavation.If this is correct select "Yes". If it is not correct then select "No" and search again or draw the location of where you are excavating.`;
        }
      } else {
        this.isAddressFound = false;
        this.noAddressErrorMessage =
          "Invalid intersection, please enter a valid intersection or draw the location of where you are excavating.";
      }
      this.searching = false;
    },
    async fetchBetweenStreets() {
      const bodyObject = {};
      bodyObject.body = {
        center: this.stateToCenterMap[this.selectedState],
        address: this.search,
      };
      const fetchBetweenStreetStatus = await this.$store.dispatch(
        "getAutoSuggestionBetween",
        bodyObject
      );
      if (fetchBetweenStreetStatus.status !== "error") {
        let features = [];
        if (
          fetchBetweenStreetStatus.intersections.length > 0 &&
          fetchBetweenStreetStatus.street_line
        ) {
          this.selectedCounty = fetchBetweenStreetStatus.counties[0].name;
          this.selectedPlace = fetchBetweenStreetStatus.location;
          this.street = this.search.split("&")[0];
          features.push({ LineString: fetchBetweenStreetStatus.street_line });
          this.addressType = "between";
          for (const intersection of fetchBetweenStreetStatus.intersections) {
            features.push({ point: intersection.point });
            features.push({ LineString: intersection.cross_line });
          }
          this.geoAddress = this.convertObjectToFeature(features);
          this.geoJson = {
            type: "FeatureCollection",
            features: this.geoAddress,
          };
          this.parcelExactInPlace = 0;
          bus.$emit("addFeature", {
            feature: this.geoJson,
            parcelIndex: 0,
          });
          const places = await this.getPlaces(
            this.selectedCenter,
            this.selectedState,
            this.selectedCounty
          );
          this.places = places;
          const isValidPlace = this.checkPlaceWithinCounty(
            places,
            this.selectedPlace
          );

          if (!isValidPlace) {
            let feature = JSON.parse(
              new format.GeoJSON().writeFeatures(
                new format.WKT().readFeatures(
                  fetchBetweenStreetStatus.street_line
                )
              )
            );
            let drawnFeature = this.getFeatureFromCoordinates(
              feature.features[0].geometry.type,
              feature.features[0].geometry.coordinates
            );
            drawnFeature = new format.GeoJSON().readFeatureFromObject(
              drawnFeature
            );
            const description = await this.getDescription(
              drawnFeature.getGeometry()
            );
            let validPlace;
            if (description.places.length) {
              const placesNames = places.map((item) => item.place);
              for (const item of description.places) {
                const nameToCheck = item.name.toUpperCase();
                if (placesNames.includes(nameToCheck)) {
                  validPlace = item.name;
                  break; // If a match is found, then break the loop
                }
              }
            }
            if (validPlace) {
              if (validPlace !== this.selectedPlace) {
                this.isAddressFound = true;
                const currentLocation = this.selectedPlace;
                this.selectedPlace = validPlace;
                this.descriptonMessage = `We have found the that street between the first and second cross street and have selected it as the area of excavation. However we can't find location "${currentLocation.toUpperCase()}", instead we found the location "${validPlace.toUpperCase()}". If this is correct select "Yes". If it is not correct then select "No" and search again or draw the location of where you are excavating.`;
              }
            } else {
              this.isAddressFound = true;
              this.showSelectPlace = true;
              let currentPlace = this.selectedPlace;
              this.descriptonMessage = `${currentPlace} is not a valid place. Please select a place. `;
              this.selectedPlace = places[0].place;
            }
          } else {
            this.descriptonMessage = `We have found the that street between the first and second cross street and have selected it as the area of excavation.If this is correct select "Yes". If it is not correct then select "No" and search again or draw the location of where you are excavating.`;
          }
        } else {
          this.isAddressFound = false;
          this.noAddressErrorMessage =
            "Invalid street or cross streets, please enter a valid street and cross streets.";
        }
      }
      this.searching = false;
    },
    showCoordinates() {
      this.descriptonMessage = null;
      const parts = this.search.split(",");
      const cords = [parts[1], parts[0]];
      this.searching = false;
      let features = [{ Point: `POINT(${parts[1]} ${parts[0]})` }];
      this.geoAddress = this.convertObjectToFeature(features);
      this.geoJson = {
        type: "FeatureCollection",
        features: this.geoAddress,
      };
      this.parcelExactInPlace = 0;

      bus.$emit("addCoordinates", {
        cords: cords,
        feature: this.geoJson,
        parcelIndex: null,
      });
    },
    async onSelectState() {
      this.resetSearchState();
      this.select = null;
      const bodyObject = {};
      this.selectedCenter = this.stateToCenterMap[this.selectedState];
      bodyObject.body = {
        state: this.selectedState,
      };
      const selectedCenter = this.newtinAuthorizeCentersList.find((item) => {
        return item.centerName === this.stateToCenterMap[this.selectedState];
      });
      if (selectedCenter) {
        this.callerType = selectedCenter.excavator_type;
        this.selectedCenterId = selectedCenter.center_id;
      }

      this.$store.state.Ticket.searchOptions = {
        state: this.selectedState,
        center: this.selectedCenter,
        place: this.selectedPlace,
        county: this.selectedCounty,
      };
      bus.$emit("stateChanged", this.selectedState);
    },
    async getStates() {
      let centersList = this.newtinAuthorizeCentersList;
      centersList.forEach(async (item) => {
        if (!item) return;
        const statesStatus = { state: item.state };
        if (statesStatus) {
          this.stateToCenterMap[statesStatus.state] = item.centerName;
          this.states.push(statesStatus);
        }
        if (centersList.length === 1 && item.default) {
          this.$nextTick(() => {
            this.selectedState = item.state;
            this.onSelectState();
          });
        }
      });
    },
    onConfirmLocation() {
      this.$store.commit("setTicketData", {
        state: this.selectedState,
        county: this.selectedCounty,
        street: this.street,
        place: this.selectedPlace,
        states: this.states,
        places: this.places,
        counties: this.counties,
        autoSuggestAddress: this.autoSuggestAddress,
        center: this.selectedCenter,
        callerType: this.callerType,
        center_id: this.selectedCenterId,
        parcelExactInPlace: this.parcelExactInPlace,
        lookupType: this.lookupType,
        search: this.search,
        addressType: this.addressType,
        cross1:
          this.addressType === "intersection"
            ? this.search.split(",")[0].split("&")[1]
            : "",
        st_from_address: this.streetFromAddress
          ? parseInt(this.streetFromAddress)
          : null,
        streetLocation: this.streetLocation,
      });
      if (
        !(
          this.$store.state.Ticket.isEdit &&
          (this.$store.state.Ticket.isEdit === "UpdateTicket" ||
            this.$store.state.Ticket.isEdit === "DestroyedMarks" ||
            this.$store.state.Ticket.isEdit === "Digin")
        )
      ) {
        this.$store.commit("setExcavationDetails", {});
      }
      bus.$emit("addGeoJson", this.geoJson);
      this.$emit("locationFound");
    },
    clickNo() {
      this.isAddressFound = false;
    },
    onClickOK() {
      this.resetSearchState();
    },
    resetSearchState() {
      this.showLatLongDrawMsg = false;
      this.selectedCounty = null;
      this.selectedPlace = null;
      //this.street = null;
      this.isAddressFound = false;
      this.descriptonMessage = null;
      this.noAddressErrorMessage = null;
      this.parcelExactInPlace = null;
      this.geoJson = null;
      this.addressType = null;
      this.selectedPlace = null;
      this.showSelectPlace = false;
      this.places = [];
      //this.streetFromAddress = null;
      this.$store.commit("setTicketData", {});
    },
    convertObjectToFeature(obj) {
      let features = [];
      const wktPointRegex = /^POINT\(-?\d+(\.\d+)?\s+-?\d+(\.\d+)?\)$/;

      const wktMultilineRegex =
        /^MULTILINESTRING\(\(\s*-?\d+(\.\d+)?\s+-?\d+(\.\d+)?(,\s*-?\d+(\.\d+)?\s+-?\d+(\.\d+)?)*\)\s*(,\s*\(\s*-?\d+(\.\d+)?\s+-?\d+(\.\d+)?(,\s*-?\d+(\.\d+)?\s+-?\d+(\.\d+)?)*\)\s*)*\)$/i;
      const wktMultiPairLineRegex =
        /^MULTILINESTRING\s*\(\s*\(\s*-?\d+(\.\d+)?\s+-?\d+(\.\d+)?\s*(,\s*-?\d+(\.\d+)?\s+-?\d+(\.\d+)?\s*)*\)\s*(,\s*\(\s*-?\d+(\.\d+)?\s+-?\d+(\.\d+)?\s*(,\s*-?\d+(\.\d+)?\s+-?\d+(\.\d+)?\s*)*\)\s*)*\)$/i;

      const wktMultipolygonRegex =
        /^MULTIPOLYGON\(\((\s*-?\d+(\.\d+)?\s+-?\d+(\.\d+)?(,\s*-?\d+(\.\d+)?\s+-?\d+(\.\d+)?)*\)\s*,\s*)*\s*-?\d+(\.\d+)?\s+-?\d+(\.\d+)?(,\s*-?\d+(\.\d+)?\s+-?\d+(\.\d+)?)*\)\s*$/;
      const wktPolygonRegex =
        /^MULTIPOLYGON\s*\(\s*\(\s*\(\s*-?\d+(\.\d+)?\s+-?\d+(\.\d+)?\s*(,\s*-?\d+(\.\d+)?\s+-?\d+(\.\d+)?\s*)*\)\s*\)\s*\)$/i;
      obj.forEach((entry) => {
        for (const prop in entry) {
          if (wktPointRegex.test(entry[prop])) {
            let point = entry[prop].match(/-?\d+\.\d+/g).map(parseFloat);
            features.push({
              type: "Feature",
              geometry: { type: "Point", coordinates: point },
            });
          }
          if (
            wktMultilineRegex.test(entry[prop]) ||
            wktMultiPairLineRegex.test(entry[prop])
          ) {
            let line = entry[prop].match(/-?\d+\.\d+/g).map(parseFloat);
            line = this.chunk(line, 2);
            features.push({
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: line,
              },
            });
          }
          if (
            wktMultipolygonRegex.test(entry[prop]) ||
            wktPolygonRegex.test(entry[prop])
          ) {
            let polygon = entry[prop].match(/-?\d+\.\d+/g);
            polygon = this.chunk(polygon, 2).map((c) => c.map(parseFloat));
            features.push({
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: [polygon],
              },
            });
          }
        }
      });
      return features;
    },
    chunk(arr, size) {
      let chunks = [];
      for (let i = 0; i < arr.length; i += size) {
        chunks.push(arr.slice(i, i + size));
      }
      return chunks;
    },
    getDescriptionMessage() {
      switch (this.addressType) {
        case "parcel":
          this.descriptonMessage = `We have found a parcel with that address and have selected it as the area of excavation.If this is correct select "Yes". If it is not correct then select "No" and search again or draw the location of where you are excavating.`;
          break;
        case "street":
          this.descriptonMessage = `We have found the street for that address and have selected it as the area of excavation. If this is correct select "Yes". If it is not correct then select "No" and search again or draw the location of where you are excavating`;
          break;
        case "point":
          this.descriptonMessage = `We have found the address point with that address and have selected it as the area of excavation.If this is correct select "Yes". If it is not correct then select "No" and search again or draw the location of where you are excavating`;
          break;
        case "intersection":
          this.descriptonMessage = `We have found the intersection for that street and cross street and have selected it as the area of excavation.If this is correct select "Yes". If it is not correct then select "No" and search again or draw the location of where you are excavating.`;
          break;
        case "between":
          this.descriptonMessage = `We have found the that street between the first and second cross street and have selected it as the area of excavation.If this is correct select "Yes". If it is not correct then select "No" and search again or draw the location of where you are excavating.`;
          break;
        default:
          this.getLocation();
      }
    },
    getAddressFromDescribe(describe) {
      let address = "";
      if (describe.street) {
        address += describe.street;
      }
      if (describe.cross1) {
        address += " & " + describe.cross1;
      }
      if (describe.cross2) {
        address += " & " + describe.cross2;
      }
      if (describe.places.length > 0) {
        address += ", " + describe.places[0].name;
      }
      return address;
    },
    async getLocation() {
      const bodyObject = {};
      const parcelExactInPlace =
        this.$store.state.Ticket.ticketData.parcelExactInPlace || 0;
      bodyObject.body = {
        center: this.$store.state.Ticket.ticketData.center,
        geom: wellknown.stringify(
          this.$store.state.Ticket.geoJson.features[parcelExactInPlace]
        ),
      };
      const geocodeDescribeResult = await this.$store.dispatch(
        "getGeoCoderDescribe",
        bodyObject
      );
      const parts = this.search;
      if (
        geocodeDescribeResult.counties.length > 0 &&
        geocodeDescribeResult.places.length > 0 &&
        geocodeDescribeResult.street
      ) {
        const showVerifyMsg = `An excavation area was drawn on the map and the address "${this.getAddressFromDescribe(
          geocodeDescribeResult
        )}" has been found. If this is correct select "Yes". If it is not correct then select "No" and draw the location again of where you are excavating.`;
        bus.$emit("showVerify", {
          msg: showVerifyMsg,
          description: geocodeDescribeResult,
        });
      } else {
        const showNoStreetMsg = `An excavation area was drawn on the map but the address could not be found in "${geocodeDescribeResult.places[0].name}".has been found. If this is correct select "Yes". If it is not correct then select "No" and draw the location again of where you are excavating.`;
        bus.$emit("showVerify", {
          msg: showNoStreetMsg,
          description: geocodeDescribeResult,
        });
      }
    },
    checkPlaceWithinCounty(places, placeName) {
      const placesNames = places.map((item) => item.place);
      if (placesNames.includes(placeName.toUpperCase())) {
        return true;
      } else {
        return false;
      }
    },
    async getDescription(geometry) {
      const geom = "SRID=4326;" + new format.WKT().writeGeometry(geometry);
      const response = await fetch(
        `${
          process.env.VUE_APP_NEWTIN_GEOLOCATOR_API
        }/geocoder/describe?center=${
          this.selectedCenter
        }&geom=${encodeURIComponent(geom)}&distance=150&extent=1320`
      );
      const data = await response.json();
      return data;
    },
    getFeatureFromCoordinates(type, coordinates) {
      let feature;
      switch (type) {
        case "Point":
          feature = turf.point(coordinates);
          break;
        case "MultiPoint":
          feature = turf.multiPoint(coordinates);
          break;
        case "LineString":
          feature = turf.lineString(coordinates);
          break;
        case "MultiLineString":
          feature = turf.multiLineString(coordinates);
          break;
        case "Polygon":
          feature = turf.polygon(coordinates);
          break;
        case "MultiPolygon":
          feature = turf.multiPolygon(coordinates);
          break;
      }
      return feature;
    },
    townshipHandlingRequired(address) {
      if (townShipHandlingStates.includes(this.selectedState)) {
        if (address.place.split(" ").pop().toLowerCase() === "township") {
          return true;
        }
        return false;
      }
    },
    async handleTownShip() {
      if (this.places.length === 0) {
        const places = await this.getPlaces(
          this.selectedCenter,
          this.selectedState,
          this.selectedCounty
        );
        this.places = places;
      }
      this.isAddressFound = true;
      this.showSelectPlace = true;
      let currentPlace = this.selectedPlace;
      this.descriptonMessage = `${currentPlace} is not a valid place. Please select a place. `;
      this.selectedPlace = this.places[0].place;
    },
    setInitialDataFromStore() {
      const {
        state,
        county,
        street,
        place,
        states,
        places,
        counties,
        autoSuggestAddress,
        center,
        callerType,
        center_id,
        parcelExactInPlace,
        lookupType,
        search,
        addressType,
        st_from_address,
        streetLocation,
      } = this.$store.state.Ticket.ticketData;

      // Assign values to component's data properties
      Object.assign(this, {
        selectedState: state,
        selectedCounty: county,
        street,
        selectedPlace: place,
        states,
        places,
        counties,
        autoSuggestAddress,
        selectedCenter: center,
        callerType,
        selectedCenterId: center_id,
        parcelExactInPlace,
        lookupType,
        search,
        addressType,
        streetFromAddress: st_from_address,
        streetLocation: streetLocation,
      });

      // Handle cross1 based on addressType
      this.cross1 =
        addressType === "intersection" && search
          ? search.split(",")[0]?.split("&")[1] || ""
          : "";
    },
  },
};
</script>

<style lang="scss">
.lookup-info-icon {
  cursor: pointer;
}
</style>
