<template>
  <v-container fluid>
    <LocationSearch ref="locationSearch" @locationFound="locationFound" />
    <LocationMap ref="locationMap" />
  </v-container>
</template>

<script>
import LocationSearch from "./LocationSearch.vue";
import LocationMap from "./LocationMap.vue";
import { bus } from "@/main";

export default {
  components: { LocationSearch, LocationMap },
  props: {},
  data() {
    return {
      html: null,
      geoJson: null,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    const refs = this.$refs;
    bus.$on("addGeoJson", (obj) => {
      if (obj) {
        this.geoJson = obj;
        this.isEditLayer = false;
      }
    });
  },
  created() {},
  methods: {
    locationFound() {
      const mapImg = document
        .querySelector("#map")
        .querySelector("canvas")
        .toDataURL("image/png");
      let isEditLayer = JSON.parse(this.$refs.locationMap.qm.getFromEdit())
        .features.length
        ? true
        : false;
      let geoJson;
      if (isEditLayer) {
        geoJson = JSON.parse(this.$refs.locationMap.qm.getFromEdit());
      } else {
        geoJson = JSON.parse(this.$refs.locationMap.qm.getFromShow());
      }
      this.$store.commit("setMapData", geoJson);
      this.$emit(
        "locationFound",
        geoJson,
        this.$refs.locationSearch.search,
        this.$refs.locationSearch.selectedPlace,
        mapImg,
        isEditLayer
      );
    },
    getCanvasImage() {
      try {
        var canvas = document.getElementById("cvsMap");
        this.html = document
          .querySelector("#map")
          .querySelector("canvas")
          .toDataURL("image/png");
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss"></style>
