<template>
  <div
    id="map"
    ref="map-root"
    style="height: 300px; border: 1px solid #ccc; background: white"
    class="mb-2"
  />
</template>
<script>
import { Style, Stroke, Fill } from "ol/style";
import "ol/ol.css";
import { Map, View, Overlay, Feature, PluggableMap } from "ol";
import * as coordinate from "ol/coordinate";
import * as control from "ol/control";
import * as events from "ol/events";
import * as extent from "ol/extent";
import * as format from "ol/format";
import * as geom from "ol/geom";
import * as interaction from "ol/interaction";
import * as layer from "ol/layer";
import * as proj from "ol/proj";
import * as render from "ol/render";
import * as reproj from "ol/reproj";
import * as source from "ol/source";
import * as sphere from "ol/sphere";
import * as style from "ol/style";
import * as tilegrid from "ol/tilegrid";
import * as webgl from "ol/webgl";
import * as wellknown from "wellknown";
import * as turf from "@turf/turf";

import "@/components/Map/QMOL.css";
import Compass from "@/assets/compass-rose.png";
import Logo from "@/components/Map/QMOL_Logo.png";
import QMOL_Data from "@/components/Map/QMOL_Data.js";
import { quickMap } from "@/components/Map/QMOL.js";

import { bus } from "@/main";
export default {
  data() {
    return {
      bufferArea: {
        JULIE: 150,
        OUPS: 200,
        UTAH: 150,
        NCOCC: 250,
        UNCC: 150,
        VUPS: 150,
        USAS: 150,
      },
    };
  },
  mounted() {
    this.onResizeHandler();
    this.qm = new quickMap({
      ol: {
        View,
        Map,
        Overlay,
        Feature,
        PluggableMap,
        control,
        coordinate,
        events,
        extent,
        format,
        geom,
        interaction,
        layer,
        proj,
        render,
        reproj,
        source,
        sphere,
        style,
        tilegrid,
        webgl,
      },
      turf: turf,
      target: "map",
      compass: Compass,
      logo: Logo,
      extraData: QMOL_Data.QMOL_Data,
    });
    this.qm.showHelp = false;
    this.qm.showIntersection = false;
    this.qm.showToggleControls = true;
    this.qm.showBlock = false;
    this.qm.showFlag = false;
    this.qm.showDraw = false;
    this.qm.showEdit = false;
    this.qm.showWeather = false;
    this.qm.showMeasure = true;
    this.qm.showBullseye = false;
    this.qm.showIdentify = false;
    this.qm.showClear = false;
    this.qm.showMeasureDirection = true;
    this.qm.showMeasureLengthFeet = true;
    this.qm.showMeasureLengthYards = false;
    this.qm.showMeasureLengthMiles = true;
    this.qm.showMeasureLengthMeters = false;
    this.qm.showMeasureLengthKilometers = false;
    this.qm.showMeasureAreaSqFeet = true;
    this.qm.showMeasureAreaSqYards = false;
    this.qm.showMeasureAreaAcres = true;
    this.qm.showMeasureAreaSqMiles = false;
    this.qm.showMeasureAreaSqMeters = false;
    this.qm.showMeasureAreaSqKilometers = false;
    this.qm.drawBuffer = 150;
    this.qm.merge = true;
    this.qm.map
      .getView()
      .fit(
        proj.transformExtent(
          [-124.848974, 24.396308, -66.885444, 49.384358],
          "EPSG:4326",
          "EPSG:3857"
        ),
        { padding: this.qm.padding }
      );
    document.addEventListener("qmolDraw", (e) => {
      this.qm.toggleButtonsOn();
      this.qm.removeInteractions();
      this.qm.map.getTargetElement().style.cursor = "default";
      this.handleDrawn(e.detail);
    });
    document.addEventListener("qmolDelete", (e) => {});
    this.bindEvents();
    document.addEventListener("qmolBtnDrawClick", (e) => {
      this.qm.clearEdit();
      this.qm.clearShow();
      this.qm.removeFromShow("type", "drawn");
      bus.$emit("drawButtonClicked");
    });
    this.setLocationData();
  },
  created() {
    window.addEventListener("resize", this.onResizeHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResizeHandler);
  },
  methods: {
    setLocationData() {
      let ticketData = this.$store.state.Ticket.ticketData;
      let geoJson = this.$store.state.Ticket.geoJson;
      if (Object.keys(geoJson).length > 0) {
        this.parcelExactInPlace = ticketData.parcelExactInPlace;
        let isEditMode =
          geoJson.features.length === 1 &&
          (ticketData.parcelExactInPlace === null ||
            ticketData.parcelExactInPlace === undefined);

        if (isEditMode) {
          this.qm.addToEdit(geoJson);
        } else {
          this.qm.addToShow(geoJson);
          //this.addBuffer(this.parcelExactInPlace);
        }
        this.qm.zoomToAll(geoJson);
      }
    },

    bindEvents() {
      bus.$on("clearMap", () => {
        this.clearMap();
      });
      this.bindStateChangeEvent();
      this.bindAddFeatureEvents();
    },
    bindStateChangeEvent() {
      bus.$on("stateChanged", (state) => {
        this.clearMap();
        this.qm.zoomToState(state);
      });
    },
    bindAddFeatureEvents() {
      bus.$on("addFeature", (obj) => {
        const features = JSON.parse(this.qm.getFromShow()).features;
        let feature = obj.feature;
        this.qm.addToShow(feature);
        if (obj.parcelIndex !== null && obj.parcelIndex !== undefined) {
          this.addBuffer(obj.parcelIndex);
        }
        this.qm.zoomToAll(feature);
        this.checkForLatLong();
      });
      bus.$on("addCoordinates", (obj) => {
        const searchOptions = this.$store.state.Ticket.searchOptions;
        if (
          !(
            this.qm.getCountyForLongLat(obj.cords) &&
            this.qm.getCountyForLongLat(obj.cords).STATE === searchOptions.state
          )
        ) {
          const errorMsg = "Lat/Long has not been found. Please try again.";
          bus.$emit("coordinatesNotFound", errorMsg);
        } else {
          bus.$emit("addFeature", {
            feature: obj.feature,
            parcelIndex: obj.parcelIndex,
          });
        }
      });
    },
    getStyle(rgba, strokeAlpha = "1", fillAlpha = "0.2") {
      return new style.Style({
        stroke: new style.Stroke({
          color: rgba.replace(")", `,${strokeAlpha})`),
          width: 2,
        }),
        fill: new style.Fill({ color: rgba.replace(")", `,${fillAlpha})`) }),
        image: new style.Circle({
          stroke: new style.Stroke({
            color: rgba.replace(")", `,${strokeAlpha})`),
            width: 2,
          }),
          fill: new style.Fill({ color: "rgba(255,255,255,1)" }),
          radius: 5,
        }),
      });
    },
    addBuffer(index) {
      const feature = JSON.parse(this.qm.getFromShow());
      let bufferdata;
      let bufferFeature;
      const searchOptions = this.$store.state.Ticket.searchOptions;
      let bufferArea =
        feature.features[index].geometry.type === "Point"
          ? this.bufferArea[searchOptions.center]
          : 100;
      bufferdata = this.qm.getFeatureData(feature)[index];
      bufferFeature = this.qm.bufferFeature(bufferdata, bufferArea);
      bufferFeature.setStyle(
        new Style({
          stroke: new Stroke({
            color: [15, 10, 255, 1],
            width: 3,
          }),
          fill: new Fill({ color: [15, 10, 255, 0.1] }),
        })
      );
      this.qm.show.getSource().addFeature(bufferFeature);
    },
    clearMap() {
      this.qm.clearShow();
      this.qm.clearEdit();
    },
    onResizeHandler(e) {
      const map = document.getElementById("map");
      const height =
        window.innerHeight -
        document.getElementById("map").getBoundingClientRect().top -
        20;
      map.style.height = Math.max(320, height) + "px";
    },

    async handleDrawn(details) {
      const describe = await this.getDescription(details.geometry);
      const searchOptions = this.$store.state.Ticket.searchOptions;
      const searchText = searchOptions.search;
      const parts = searchText ? searchText.split(",") : [];
      if (parts.length === 2 && !isNaN(parts[0]) && !isNaN(parts[1])) {
        const addressRequiredMsg = `An excavation area was drawn on the map for the latitude and longitude entered in but an address is also required.`;
        bus.$emit("showVerify", {
          msg: addressRequiredMsg,
          description: describe,
        });
      } else {
        if (
          describe.counties.length > 0 &&
          describe.places.length > 0 &&
          describe.street
        ) {
          const showVerifyMsg = `An excavation area was drawn on the map and the address "${this.getAddressFromDescribe(
            describe
          )}" has been found. If this is correct select "Yes". If it is not correct then select "No" and draw the location again of where you are excavating.`;
          bus.$emit("showVerify", {
            msg: showVerifyMsg,
            description: describe,
          });
        } else {
          const showNoStreetMsg = `An excavation area was drawn on the map but the address could not be found in "${describe.places[0].name}". If this is correct select "Yes". If it is not correct then select "No" and draw the location again of where you are excavating.`;
          bus.$emit("showNostreetMsg", {
            msg: showNoStreetMsg,
            description: describe,
          });
        }
      }
    },
    async getDescription(geometry) {
      const searchOptions = this.$store.state.Ticket.searchOptions;
      const geom = "SRID=4326;" + new format.WKT().writeGeometry(geometry);
      const response = await fetch(
        `${
          process.env.VUE_APP_NEWTIN_GEOLOCATOR_API
        }/geocoder/describe?center=${
          searchOptions.center
        }&geom=${encodeURIComponent(geom)}&distance=${
          this.qm.drawBuffer
        }&extent=1320`
      );
      const data = await response.json();
      return data;
    },
    getAddressFromDescribe(describe) {
      let address = "";
      if (describe.street) {
        address += describe.street;
      }
      if (describe.cross1) {
        address += " & " + describe.cross1;
      }
      if (describe.cross2) {
        address += " & " + describe.cross2;
      }
      if (describe.places.length > 0) {
        address += ", " + describe.places[0].name;
      }
      return address;
    },
    checkForLatLong() {
      const searchOptions = this.$store.state.Ticket.searchOptions;
      const parts = searchOptions.search.split(",");
      if (parts.length === 2 && !isNaN(parts[0]) && !isNaN(parts[1])) {
        // emit event to show message to draw excavation area
        bus.$emit("coordinatesAdded");
      }
    },

    // TODO replace getDescription with this
    async getCountyPlacesWithReverseGeocode(details) {
      const bodyObject = {};
      const searchOptions = this.$store.state.Ticket.searchOptions;
      bodyObject.body = {
        center: searchOptions.center,
        geom: wellknown.stringify(details.features.features[0]),
      };
      const reversGeoCode = await this.$store.dispatch(
        "getCountyPlacesWithReverseGeocode",
        bodyObject
      );
    },
  },
};
</script>
