<script>
import * as wellknown from "wellknown";
import { bus } from "@/main";
export default {
  methods: {
    async getCounties(center, state) {
      const payload = {
        center: center,
        state: state,
      };
      const resp = await this.$store.dispatch("getCounties", payload);
      return resp;
    },
    async getPlaces(center, state, county) {
      const payload = {
        center: center,
        state: state,
        county: county,
      };
      const resp = await this.$store.dispatch("getPlaces", payload);
      return resp;
    },
    async getLocation() {
      const bodyObject = {};
      const searchOptions = this.$store.state.Ticket.searchOptions;
      const features = JSON.parse(this.qm.getFromShow()).features;
      bodyObject.body = {
        center: searchOptions.center,
        geom: wellknown.stringify(features[features.length - 1]),
      };
      const geocodeDescribeResult = await this.$store.dispatch(
        "getGeoCoderDescribe",
        bodyObject
      );
      if (
        geocodeDescribeResult.counties.length > 0 &&
        geocodeDescribeResult.places.length > 0
      ) {
        bus.$emit("setCoordinateData", geocodeDescribeResult);
      }
    },
  },
};
</script>
