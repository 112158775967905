var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("LocationSearch", {
        ref: "locationSearch",
        on: { locationFound: _vm.locationFound },
      }),
      _c("LocationMap", { ref: "locationMap" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }